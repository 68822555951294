<template>
  <div class="home">
    <div class="banner">
      <el-carousel>
        <el-carousel-item v-for="item in bannerItem" :key="item.title">
          <a :href="item.jump_url">
            <img :src="item.url" :alt="item.title" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="announcement">
      <el-carousel
        height="50px"
        direction="vertical"
        :autoplay="true"
        class="announcement-box"
      >
        <h2><span class="icon-volume-down1"></span>通知公告：</h2>
        <div class="info-box">
          <el-carousel-item v-for="item in dataInfo" :key="item.content_id">
            <a :href="item.thumb" style="color:#666">
              {{ item.title }}，{{ item.summary }}
            </a>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
    <div class="product-system">
      <div class="title">
        <h2><span>产品体系</span></h2>
      </div>
      <div class="product-info">
        <el-row :gutter="20">
          <el-col :span="4" v-for="item in licenceData" :key="item.licence_id">
            <div class="product-item">
              <div class="item-title">
                <p><span :class="item.classname"></span></p>
                {{ item.licence_name }}
              </div>
              <el-row class="item-list">
                <el-col
                  :span="12"
                  v-for="item2 in item.children"
                  :key="item2.lience_id"
                >
                  <a
                    @click="videoplay(item2.licence_id)"
                    :title="item2.licence_name"
                  >
                    {{ item2.licence_name }}
                  </a>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="product-about">
      <div class="product-about-info">
        <div class="title">
          <h2><span>产品介绍</span></h2>
        </div>
        <img src="../assets/images/product.png" alt="" />
      </div>
    </div>
    <div class="news-box">
      <div class="title">
        <h2><span>安全资讯</span></h2>
      </div>
      <el-row :gutter="20" class="news-list">
        <el-col :span="12">
          <div class="title">
            <h2>安全警示</h2>
            <a @click="clickMore(26)">更多>></a>
          </div>
          <ul>
            <li v-for="(item, index) in anquan" :key="index">
              <a :href="'#/news/detail?id=' + item.content_id">{{
                item.title
              }}</a
              ><span>{{ item.create_time }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="12">
          <div class="title">
            <h2>行业资讯</h2>
            <a @click="clickMore(25)">更多>></a>
          </div>
          <ul>
            <li v-for="(item, index) in hangye" :key="index">
              <a :href="'#/news/detail?id=' + item.content_id">{{
                item.title
              }}</a
              ><span>{{ item.create_time }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getlicence } from '../api/licence'
import {
  getIsPay,
  getResult,
  getTerm,
  setSubjectId,
  setResult,
  setLicenceName,
  setIsPay,
  getSubjectId,
  removeResult
} from '../utils/auth'
import { ad, articleList, notice } from '../api/article'
import { userlicence, changelicence } from '../api/user'
import { getSubject } from '../api/courses'

export default {
  name: 'Home',
  inject: ['reload'],
  data() {
    return {
      hangye: [],
      newhangye: [],
      anquan: [],
      newanquan: [],
      licenceData: [],
      licence_id: '',
      title: '',
      dataInfo: [],
      bannerItem: []
    }
  },
  created() {
    if (getResult() === undefined) {
      // this.$alert('登录过期，请登录后再访问', '登录提示', {
      //   confirmButtonText: '重新登录',
      //   callback: action => {
      //     removeResult()
      //     location.href = '#/login'
      //   }
      // })
      this.licence_id = 2
    } else {
      this.licence_id = JSON.parse(getResult()).licence_id
    }
    this.getlicenceData()
    this.getCarData()
    this.getAd()
    this.getNotice()
  },
  methods: {
    getlicenceData() {
      getlicence().then(res => {
        if (res.code === 0) {
          this.licenceData = res.result
          for (let i = 0; i < this.licenceData.length; i++) {
            if (this.licenceData[i].classname === null) {
              this.licenceData[i].classname = 'icon-car2'
              if (this.licenceData[i].hasOwnProperty('children') === true) {
                for (let s = 0; s < this.licenceData[i].children.length; s++) {
                  if (this.licenceData[i].children[s].classname === null) {
                    this.licenceData[i].children[s].classname = 'icon-car2'
                  }
                }
              }
            }
          }
        }
      })
    },
    //轮播图
    getAd() {
      ad({ licence_id: this.licence_id, id: 100 }).then(res => {
        if (res.code === 0) {
          this.bannerItem = res.result
        }
      })
    },
    //通知公告
    getNotice() {
      notice({ category_id: 14 }).then(res => {
        if (res.code === 0) {
          this.dataInfo = res.result
        }
      })
    },
    //产品体系
    videoplay(licence_id) {
      if (getResult() === undefined) {
        // 未登录
        let routeUrl = this.$router.resolve({
          path: '/catalogs/shikan?licence_id=' + licence_id
        })
        window.open(routeUrl.href, '_blank')
      } else {
        if (JSON.parse(getResult()).token != null) {
          // 已登录
          if (getIsPay() >= 2) {
            // let routeUrl = this.$router.resolve({
            //   path: 'catalogs?licence_id=' + licence_id
            // })
            // window.open(routeUrl.href, '_blank')
            userlicence().then(res => {
              if (res.code === 0) {
                for (let i = 0; i < res.result.length; i++) {
                  for (let s = 0; s < res.result[i].data.length; s++) {
                    if (licence_id === res.result[i].data[s].licence_id) {
                      // this.$confirm('请在个人中心切换车型并前往观看', '提示', {
                      //   confirmButtonText: '确定',
                      //   // cancelButtonText: '取消',
                      //   type: 'warning'
                      // })
                      // return

                      let arr = JSON.parse(getResult())
                      arr.licence_id = res.result[i].data[s].licence_id
                      arr.licence_title = res.result[i].data[s].licence_title
                      setResult(arr)
                      setLicenceName(res.result[i].data[s].licence_name)
                      setIsPay(res.result[i].data[s].term[0].is_pay)

                      let params = {
                        licence_id: licence_id,
                        licence_title: res.result[i].data[s].licence_title,
                        term: getTerm(),
                        study_no: ''
                      }
                      changelicence(params).then(res => {
                        if (res.code == 0) {
                          getSubject({
                            licence_id: JSON.parse(getResult()).licence_id
                          }).then(res => {
                            if (res.code === 0) {
                              this.subjectdata = res.result.subject_list
                              setSubjectId(this.subjectdata[0].subject_id)
                              this.reload()
                              let routeUrl = this.$router.resolve({
                                path: 'catalogs?licence_id=' + licence_id
                              })
                              window.open(routeUrl.href, '_blank')
                            }
                          })
                        }
                        if (res.code !== 0) {
                          this.$message({
                            message: '车型更换失败！',
                            type: 'warning'
                          })
                        }
                      })
                      return
                    }
                    // if (licence_id !== res.result[i].data[s].licence_id) {
                    //   let routeUrl = this.$router.resolve({
                    //     path: '/catalogs/shikan?licence_id=' + licence_id
                    //   })
                    //   window.open(routeUrl.href, '_blank')
                    //   return
                    // }
                  }
                }
                let routeUrl = this.$router.resolve({
                  path: '/catalogs/shikan?licence_id=' + licence_id
                })
                window.open(routeUrl.href, '_blank')
                return
              }
            })
          } else {
            let routeUrl = this.$router.resolve({
              path: '/catalogs/shikan?licence_id=' + licence_id
            })
            window.open(routeUrl.href, '_blank')
          }
        }
      }
    },
    //资讯
    getCarData() {
      articleList({ category_id: 25 }).then(res => {
        if (res.code === 0) {
          this.hangye = res.result.slice(0, 6)
          this.newhangye = res.result
        }
      })
      articleList({ category_id: 26 }).then(res => {
        if (res.code === 0) {
          this.anquan = res.result.slice(0, 6)
          this.newanquan = res.result
        }
      })
    },
    //点击更多
    clickMore(type) {
      if (type === 25) {
        this.hangye = this.newhangye
      } else if (type === 26) {
        this.anquan = this.newanquan
      }
    }
  }
}
</script>
